import { useEffect, useCallback, useMemo, useState } from 'react'

import { AuthContext, User } from './AuthContext'

const USER_EMAIL_KEY = 'user_email'

type Props = {
  children: React.ReactNode
}

export function AuthProvider({ children }: Props) {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const initialize = useCallback(async () => {
    try {
      const email = localStorage.getItem(USER_EMAIL_KEY)

      if (email) {
        setUser({ password: 'some-password', email })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    initialize()
  }, [initialize])

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const data = {
      email,
      password,
    }

    localStorage.setItem(USER_EMAIL_KEY, data.email)

    setUser(data)
  }, [])

  // LOGOUT
  const logout = useCallback(async () => {
    localStorage.removeItem(USER_EMAIL_KEY)
  }, [])

  const checkAuthenticated = user ? 'authenticated' : 'unauthenticated'

  const status = loading ? 'loading' : checkAuthenticated

  const memoizedValue = useMemo(
    () => ({
      user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
    }),
    [login, logout, user, status]
  )

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  )
}
