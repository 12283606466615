import { createContext } from 'react'

export interface User {
  email: string
  password: string
}

export type JWTContextType = {
  user: User | null
  method: string
  loading: boolean
  authenticated: boolean
  unauthenticated: boolean
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

export const AuthContext = createContext({} as JWTContextType)
