export const accounts = [
  {
    'current-account': [
      {
        id: 'd9116bd5-b268-4319-9855-d25a3cafe119',
        balance: 1e6,
        availableBalance: 30377,
        alias: 'ADAM PAUL STEVENS',
        currency: 'GBP',
        limit: 5e2,
        status: 'open',
        type: 'Current-Account',
        subType: 'CA',
        minimumRepaymentAmount: null,
        minimumRepaymentDate: null,
        lastStatementDate: null,
        accountIdentification: {
          sortCode: '159900',
          arrangementId: '40040001',
          iban: 'GC23HOAB15990080088340',
        },
        accountLink: null,
        creditInterest: null,
        paymentOptions: [
          {
            id: 1,
            transactionLimit: null,
            dailyTransactionLimit: null,
            charges: null,
            supportedCurrencies: ['GBP'],
          },
        ],
        additionalInformation: {
          debitInterest: 1.05,
          creditInterest: 0.0,
          averageBalance: 0,
        },
        eligibility: {
          xfer: true,
          chaps: true,
          tpp: true,
          intl: true,
          salary: true,
          supplier: true,
          salaryUPLD: true,
          supplierUPLD: true,
          dd: true,
          eStatement: true,
          so: true,
          chapsCharge: true,
          tppCharge: false,
          paperStatementFlag: true,
        },
      },
      {
        id: '07eaac02-3c43-4bee-b6d7-e344714ff0e4',
        balance: 0,
        availableBalance: 1029,
        alias: 'ADAM P STEVENS NO3 A/C',
        currency: 'GBP',
        limit: 5e1,
        status: 'open',
        type: 'Current-Account',
        subType: 'CA',
        minimumRepaymentAmount: null,
        minimumRepaymentDate: null,
        lastStatementDate: null,
        accountIdentification: {
          sortCode: '159900',
          arrangementId: '40040005',
          iban: 'GC23HOAB15990080088340',
        },
        accountLink: {
          id: 'be07aaff-6fd8-448c-9b6b-ae04edc613af',
          name: 'ADAM PAUL STEVENS SET OFF FULL',
        },
        creditInterest: null,
        paymentOptions: [
          {
            id: 1,
            transactionLimit: null,
            dailyTransactionLimit: null,
            charges: null,
            supportedCurrencies: ['GBP'],
          },
        ],
        additionalInformation: {
          debitInterest: 1.05,
          creditInterest: 0.0,
          averageBalance: 0,
        },
        eligibility: {
          xfer: true,
          chaps: false,
          tpp: false,
          intl: false,
          salary: false,
          supplier: false,
          salaryUPLD: false,
          supplierUPLD: false,
          dd: true,
          eStatement: true,
          so: true,
          chapsCharge: false,
          tppCharge: false,
          paperStatementFlag: false,
        },
      },
    ],
  },
  {
    'visa-account': [
      {
        id: '3c1794d6-acb2-4d8f-ac43-125d0cab2f78',
        balance: null,
        availableBalance: 1e6,
        alias: 'ADAM PAUL STEVENS',
        currency: 'GBP',
        limit: 5e3,
        status: 'open',
        type: 'Visa',
        subType: 'VA',
        minimumRepaymentAmount: 1e1,
        minimumRepaymentDate: '2016-09-10T00:00:00Z',
        lastStatementDate: '2016-08-10T00:00:00Z',
        accountIdentification: {
          sortCode: null,
          arrangementId: '120199XXXXXX4455',
          iban: null,
        },
        accountLink: null,
        creditInterest: null,
        paymentOptions: [
          {
            id: 1,
            transactionLimit: null,
            dailyTransactionLimit: null,
            charges: null,
            supportedCurrencies: ['GBP'],
          },
        ],
        additionalInformation: { debitInterest: 0.0, creditInterest: 6.0 },
        eligibility: {
          xfer: true,
          chaps: true,
          tpp: true,
          intl: true,
          salary: true,
          supplier: true,
          salaryUPLD: true,
          supplierUPLD: true,
          dd: true,
          eStatement: true,
          so: true,
          chapsCharge: true,
          tppCharge: false,
          paperStatementFlag: false,
        },
      },
      {
        id: '2951e70a-84fd-43f0-bc50-b1e655e47f73',
        balance: null,
        availableBalance: 198200,
        alias: 'ADAM PAUL STEVENS',
        currency: 'GBP',
        limit: 5e3,
        status: 'open',
        type: 'Visa',
        subType: 'VA',
        minimumRepaymentAmount: 1e1,
        minimumRepaymentDate: '2016-09-10T00:00:00Z',
        lastStatementDate: '2016-08-10T00:00:00Z',
        accountIdentification: {
          sortCode: null,
          arrangementId: '1206120609864240',
          iban: null,
        },
        accountLink: null,
        creditInterest: null,
        paymentOptions: [
          {
            id: 1,
            transactionLimit: null,
            dailyTransactionLimit: null,
            charges: null,
            supportedCurrencies: ['GBP'],
          },
        ],
        additionalInformation: { debitInterest: 95.05, creditInterest: 0.0 },
        eligibility: {
          xfer: true,
          chaps: true,
          tpp: true,
          intl: true,
          salary: true,
          supplier: true,
          salaryUPLD: true,
          supplierUPLD: true,
          dd: true,
          eStatement: true,
          so: true,
          chapsCharge: true,
          tppCharge: false,
          paperStatementFlag: false,
        },
      },
    ],
  },
  {
    'deposit-account': [
      {
        id: '8c9ce0cc-b5a7-4023-98fd-dcdbeda29ec8',
        balance: 5e4,
        availableBalance: 50,
        alias: 'ADAM PAUL STEVENS FTD 1',
        currency: 'GBP',
        limit: 0,
        status: 'open',
        type: 'Deposit-Account',
        subType: 'FTD1YEAR',
        minimumRepaymentAmount: null,
        minimumRepaymentDate: null,
        lastStatementDate: null,
        accountIdentification: {
          sortCode: '159900',
          arrangementId: '80009722',
          iban: 'GC23HOAB15990080088340',
        },
        accountLink: null,
        creditInterest: null,
        paymentOptions: [
          {
            id: 1,
            transactionLimit: null,
            dailyTransactionLimit: null,
            charges: null,
            supportedCurrencies: ['GBP'],
          },
        ],
        additionalInformation: { debitInterest: 95.05, creditInterest: 0.0 },
        eligibility: {
          xfer: true,
          chaps: true,
          tpp: true,
          intl: true,
          salary: true,
          supplier: true,
          salaryUPLD: true,
          supplierUPLD: true,
          dd: true,
          eStatement: true,
          so: true,
          chapsCharge: true,
          tppCharge: false,
          paperStatementFlag: false,
        },
      },
    ],
  },
  {
    'loan-account': [
      {
        id: 'a5370394-4dfb-405c-9d13-7f9088108ceb',
        balance: -34126.91,
        availableBalance: 5679122,
        alias: 'ADAM PAUL STEVENS LOAN',
        currency: 'GBP',
        limit: 2e3,
        status: 'open',
        type: 'Loan-Account',
        subType: 'VA',
        minimumRepaymentAmount: null,
        minimumRepaymentDate: null,
        lastStatementDate: null,
        accountIdentification: {
          sortCode: '159900',
          arrangementId: '80080001',
          iban: 'GC23HOAB15990080088340',
        },
        accountLink: null,
        creditInterest: null,
        paymentOptions: [
          {
            id: 1,
            transactionLimit: null,
            dailyTransactionLimit: null,
            charges: null,
            supportedCurrencies: ['GBP'],
          },
        ],
        additionalInformation: {
          debitInterest: 1.05,
          creditInterest: 0.0,
          averageBalance: 0,
        },
        eligibility: {
          xfer: true,
          chaps: true,
          tpp: true,
          intl: true,
          salary: true,
          supplier: true,
          salaryUPLD: true,
          supplierUPLD: true,
          dd: true,
          eStatement: true,
          so: true,
          chapsCharge: true,
          tppCharge: false,
          paperStatementFlag: false,
        },
      },
      {
        id: 'f5fd1cbd-11e3-4d1c-b556-92f6db9cba94',
        balance: -183.54,
        availableBalance: 18999,
        alias: 'ADAM PAUL STEVENS LOAN 2',
        currency: 'GBP',
        limit: 1e4,
        status: 'open',
        type: 'Loan-Account',
        subType: 'VA',
        minimumRepaymentAmount: null,
        minimumRepaymentDate: null,
        lastStatementDate: null,
        accountIdentification: {
          sortCode: '159900',
          arrangementId: '80080002',
          iban: 'GC23HOAB15990080088340',
        },
        accountLink: null,
        creditInterest: null,
        paymentOptions: [
          {
            id: 1,
            transactionLimit: null,
            dailyTransactionLimit: null,
            charges: null,
            supportedCurrencies: ['GBP'],
          },
        ],
        additionalInformation: {
          debitInterest: 1.05,
          creditInterest: 0.0,
          averageBalance: 0,
        },
        eligibility: {
          xfer: true,
          chaps: true,
          tpp: true,
          intl: true,
          salary: true,
          supplier: true,
          salaryUPLD: true,
          supplierUPLD: true,
          dd: true,
          eStatement: true,
          so: true,
          chapsCharge: true,
          tppCharge: false,
          paperStatementFlag: false,
        },
      },
    ],
  },
]
