import React from 'react'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { redirect } from 'react-router-dom'

// @mui
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import { Box, Container, useTheme } from '@mui/material'

// components
import Iconify from 'views/components/Iconify'
import FormProvider, { RHFTextField } from 'views/components/HookForm'

// hooks
import { useAuthContext, useBoolean } from 'hooks'
import { useSettingsContext } from 'settings'

const Login: React.FC = () => {
  const { login } = useAuthContext()
  const theme = useTheme()
  const settings = useSettingsContext()

  const password = useBoolean()

  const [errorMsg, setErrorMsg] = useState('')

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  })

  const defaultValues = {
    email: 'demo@creditunion.io',
    password: 'demo1234',
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login?.(data.email, data.password)

      redirect('/dashboard')
    } catch (error) {
      console.error(error)
      reset()
      setErrorMsg(typeof error === 'string' ? error : error.message)
    }
  })

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 7 }}>
      <Typography variant="h3">Welcome to online banking</Typography>
    </Stack>
  )

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <RHFTextField name="email" label="Email address" />

      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify
                  icon={
                    password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ mt: 5 }}
      >
        Login
      </LoadingButton>
    </Stack>
  )

  return (
    <>
      <Box
        sx={{
          height: '102px',
          mb: 2,
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        }}
      >
        <img src="/logo.svg" alt="logo"></img>
      </Box>
      <Container
        maxWidth={settings.themeStretch ? false : 'sm'}
        sx={{ mt: 10 }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          {renderHead}

          {renderForm}
        </FormProvider>
      </Container>
    </>
  )
}

export default Login
