import React from 'react'
import { Stack, useTheme, Typography } from '@mui/material'

import { accounts } from 'mock'
import Account from './Account'
import Iconify from 'views/components/Iconify'

export const ACCOUNT_TYPE_TITLES: { [key: string]: string } = {
  'current-account': 'Current Accounts',
  'visa-account': 'Card Accounts',
  'deposit-account': 'Deposit Accounts',
  'loan-account': 'Loan Accounts',
  card: 'Credit Cards',
  investment: 'Investment',
  mortgage: 'Mortgage',
  loan: 'Loans',
  'current-portfolio': 'Current Portfolios',
  'investment-account': 'Investment Accounts',
}

export const getAccountGroupLabel = (groupKey: string): string =>
  ACCOUNT_TYPE_TITLES[groupKey]?.toUpperCase() || ''

const Accounts: React.FC = () => {
  const theme = useTheme()

  return (
    <Stack
      spacing={3}
      sx={{
        my: 4,
        mx: 1,
        fontSize: '14px',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 0.5 }}
        alignItems="center"
      >
        <Typography sx={{ fontSize: '18px', fontWeight: '100' }}>
          Accounts
        </Typography>
        <Iconify icon="bytesize:settings" />
      </Stack>

      {accounts.map((accountObj, i) => (
        <Stack
          key={i}
          sx={{
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '5px',
          }}
        >
          {Object.keys(accountObj).map((accountType, i) => (
            <Stack
              key={i}
              sx={{
                backgroundColor: theme.palette.text.disabled,
                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                p: 1,
                fontWeight: 550,
              }}
            >
              {ACCOUNT_TYPE_TITLES[accountType]}
            </Stack>
          ))}
          {Object.values(accountObj).map((accounts) =>
            accounts.map((account) => (
              <Account key={account.id} account={account} />
            ))
          )}
        </Stack>
      ))}
    </Stack>
  )
}

export default Accounts
