import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'

// @mui
import { Box, Card, CardHeader, Container, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

// settings
import { useSettingsContext } from 'settings'

// components
import Accounts from './Accounts/Accounts'
import QuickLinks from './QuickLinks'

const Dashboard: React.FC = () => {
  const settings = useSettingsContext()
  const theme = useTheme()
  const effectRan = useRef(false)
  const [token, setToken] = useState('')
  const [searchParams] = useSearchParams()
  const customerId = searchParams.get('customer_id')

  const getToken = async () => {
    try {
      const response = await axios.post(
        'https://dev-api.trustek.io/api/widget/customer_token',
        { customer_id: customerId || '34d5f39c-7fbd-4fac-9472-373681142340' }
      )

      if (response.data) {
        setToken(response.data.token)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!effectRan.current && !token) {
      getToken()
      effectRan.current = true
    }

    return () => {
      effectRan.current = true
    }
  }, [effectRan, token])

  useEffect(() => {
    if (token) {
      window.TrustekWidget.create({
        elementId: 'widget',
        token,
      })
    }
  }, [token])

  return (
    <>
      <Box
        sx={{
          height: '102px',
          mb: 2,
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        }}
      >
        <img src="/logo.svg" alt="logo"></img>
      </Box>
      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{ mt: 10 }}
      >
        <Grid container spacing={5}>
          <Grid xs={12} md={12} sm={12}>
            <Card
              variant="outlined"
              sx={{
                borderRadius: 0.5,
                borderColor: 'divider',
              }}
            >
              <CardHeader
                title="Dashboard"
                sx={{ backgroundColor: theme.palette.text.disabled, py: 2 }}
              />
              <Grid container spacing={1} sx={{ mx: 3 }}>
                <Grid xs={12} md={7}>
                  <Accounts />
                </Grid>
                <Grid xs={12} md={5}>
                  <QuickLinks />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Dashboard
