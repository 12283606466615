import React from 'react'

import { Stack, Typography, useTheme } from '@mui/material'
import Iconify from 'views/components/Iconify'

const QuickLinks: React.FC = () => {
  const theme = useTheme()

  return (
    <Stack
      spacing={1}
      sx={{
        my: 4,
        mx: 'auto',
        fontSize: '14px',
        width: '360px',
      }}
    >
      <Typography sx={{ fontSize: '18px', fontWeight: '100' }}>
        Quick Links
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          color: theme.palette.primary.main,
          px: 1,
          py: 2,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        }}
      >
        Southland Investment Services
        <Iconify icon="line-md:external-link" />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          color: theme.palette.primary.main,
          px: 1,
          py: 2,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        }}
      >
        Midwest Mortgage Payment
        <Iconify
          icon="line-md:external-link"
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        />
      </Stack>

      <Stack
        sx={{ position: 'relative', height: '630px' }}
        alignItems="center"
        id="widget"
      ></Stack>
    </Stack>
  )
}

export default QuickLinks
