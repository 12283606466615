import React from 'react'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'

import Dashboard from 'views/pages/Dashboard'
import Login from 'views/pages/Login'
import { useAuthContext } from 'hooks'

const AppRouter: React.FC = () => {
  const { authenticated, unauthenticated, loading } = useAuthContext()

  if (unauthenticated && !loading) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    )
  }

  if (authenticated && !loading) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard/:customer_id" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    )
  }

  return null
}

export default AppRouter
