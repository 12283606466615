import React from 'react'
import { Stack, useTheme, Typography, Box } from '@mui/material'

import { fCurrency } from 'utils/formatNumber'
import Iconify from 'views/components/Iconify'

interface AccountProps {
  account: Record<string, any>
}

const getRandomColor = () => {
  const hex = Math.floor(Math.random() * 0xffffff)

  return '#' + hex.toString(16)
}

const Account: React.FC<AccountProps> = ({ account }) => {
  const theme = useTheme()

  return (
    <Stack
      spacing={0.5}
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      sx={{
        p: 2,
        fontSize: '14px',
        ':not(:last-child)': {
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
        },
      }}
    >
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            height: '45px',
            backgroundColor: getRandomColor(),
            width: '7px',
          }}
        ></Box>
        <Stack>
          <Typography variant="body2">{account.alias}</Typography>
          <Typography variant="body2">
            {account.accountIdentification.iban ||
              account.accountIdentification.arrangementId}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack>
          <Box>Available balance</Box>
          <Box>{fCurrency(account.availableBalance)}</Box>
        </Stack>

        <Iconify icon="uim:angle-right-b" />
      </Stack>
    </Stack>
  )
}

export default Account
