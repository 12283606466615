import AppRouter from './views/templates/AppRouter'
import { AuthProvider } from './auth'
import ThemeProvider from 'theme'
import { SettingsProvider } from 'settings'
import SettingsDrawer from 'settings/settings/drawer'

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <SettingsDrawer />
          <ThemeProvider>
            <AppRouter />
          </ThemeProvider>
        </SettingsProvider>
      </AuthProvider>
    </div>
  )
}

export default App
